import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getBookingData } from "../actions/billActions";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

const format_rupees = (val) => {
  return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0 }).format(val);
}

const format_date = (val) => {
  var customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);
  let format = ["DD/MM/YYYY hh:mm:ss a", "D/M/YYYY H:mm:ss a", "YYYY-MM-DDTHH:mm:ss.SSSZ"];
  return dayjs(val, format).format("DD-MM-YYYY hh:mm a")
}

let csvData = [];
const Bookings = () => {
  const history = useHistory();
  const userInfo = useSelector(state => state.userLogin.userInfo)
  const [startDate, setStartDate] = useState(new Date(dayjs().subtract(1, 'day')));
  const [endDate, setEndDate] = useState(new Date(dayjs().add(1, 'month')));
  const [patientName, setPatientNme] = useState("");
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.billInfo.bookData);
  const loading = useSelector((state) => state.billInfo.bookLoading);
  const [total, setTotal] = useState({netAmount: 0, received: 0, balance: 0})
  const { SearchBar } = Search;

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useLayoutEffect(() => {
    handleSearch()
  }, [])

  const exportToCsv = () => {
    if (!loading && invoices !== undefined) {
      try {
        let totalNet = 0;
        let totalRec = 0;
        let totalBal = 0;
        let rtData = invoices.map((o1) => {
          o1.items = JSON.parse(o1.items)
          let testItems = o1.items.map((o2) => {
            const testItems = (({ description }) => (description))(o2);
            return testItems;
          })
          o1.items = testItems.toString();
          o1.balance = Number(o1.net_amount - o1.advance)
          totalNet += o1.net_amount;
          totalRec += o1.advance;
          totalBal += o1.balance;
          const picked = (({ invoice_no, invoice_date, patient_name, patient_age, patient_gender, items, patient_mobile, reference_doctor, net_amount, balance, payment_type }) => ([invoice_no, format_date(invoice_date), patient_name, patient_age, patient_gender, items, patient_mobile, reference_doctor, net_amount, balance, payment_type]))(o1);
          return picked
        })
        setTotal({netAmount: totalNet, received: totalRec, balance: totalBal})
        csvData = [
          ["TRF No.", "TRF Date", "Patient Name", "Age", "Gender", "Test Name with Test Code", "Mobile Number", "Reference Doctor", "Total Amount", "Balance Amount", "Payment Type"],
          ...rtData
        ]
      } catch (error) {
      }
    }
  }

  const handleSearch = () => {
    let filters = {
      start_date: startDate,
      end_date: endDate,
      patient_name: patientName
    }
    dispatch(getBookingData(filters))
  }

  const editInvoice = (invoiceNo) => {
    window.location = `/booking?id=${invoiceNo}`
  }

  const deleteBooking = async (invoiceNo) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/v1/booking/delete/${invoiceNo}`, config).then(res => {
      if(res.status === 200) {
        alert("Deleted successfully")
        handleSearch();
      }
    }).catch(() => {
        alert("Unable to delete, Please try after sometime")
     })
  }

  if (invoices && !csvData.length != invoices.length +1) {
    exportToCsv();
  }

  const columns = [
    {
      dataField: "booking_date",
      text: "Booking Date",
      sort: true,
      // headerStyle: { width: '200px' },
      formatter: cell => format_date(cell),
      style: { textAlign: 'right' }
    },
    {
      dataField: "patient_name",
      text: "Patient Name",
      // headerStyle: { width: '150px' },
      sort: true
    },
    {
      dataField: "patient_gender",
      text: "Gender",
      sort: true
    },
    {
      dataField: "patient_mobile",
      text: "Mobile No.",
      headerAlign: 'right',
      sort: true
    },
    {
      dataField: "net_amount",
      text: "Net Amount",
      headerAlign: 'right',
      sort: true,
      formatter: cell => format_rupees(cell)
    },
    {
      dataField: "user_name",
      text: "Assigned To",
      headerAlign: 'right',
      sort: true
    },
    {
      dataField: "id",
      text: "Action",
      editable: false,
      headerAlign: 'center',
      style: { textAlign: 'center' },
      headerStyle: { width: '150px' },
      formatter: (cellContent, row, index) => {
        return (
          <div>
            <Button
              variant="link"
              title="Edit Booking"
              size="sm"
              className="py-0"
              onClick={() => editInvoice(row.id)}
            >
              <i className="fa text-warning fa-pencil fa-2x"></i>
            </Button>{" "}
            <Button
              variant="link"
              title="Delete Booking"
              size="sm"
              className="py-0"
              onClick={() => deleteBooking(row.id)}
            >
              <i className="fa text-danger fa-trash fa-2x"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const mobColumns = [
    {
      dataField: "booking_date",
      text: "Booking Date",
      sort: true,
      formatter: cell => format_date(cell),
      style: { textAlign: 'right' }
    },
    {
      dataField: "patient_name",
      text: "Patient Name",
      sort: true
    },
    {
      dataField: "id",
      text: "Action",
      editable: false,
      headerAlign: 'center',
      style: { textAlign: 'center' },
      formatter: (cellContent, row, index) => {
        return (
          <div>
            <Button
              variant="link"
              title="Edit TRF"
              size="sm"
              className="py-0 mb-2"
              onClick={() => editInvoice(row.id)}
            >
              <i className="fa text-warning fa-pencil fa-2x"></i>
            </Button>{" "}
            <Button
              variant="link"
              title="Delete Booking"
              size="sm"
              className="py-0"
              onClick={() => deleteBooking(row.id)}
            >
              <i className="fa text-danger fa-trash fa-2x"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Card className="p-2 py-4">
        <Row className="w-100 m-auto">
          <h3 className="w-100 text-center">Bookings</h3>
          <Col md={12}>
            <Row className="mx-auto">
              <Col md="4">
                <Form.Group
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column>
                    Date Between
                  </Form.Label>
                  <Col>
                    <DatePicker
                      placeholderText="Select Date Range"
                      className="form-control mb-1"
                      selected={startDate}
                      onChange={onDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column>
                    Patient Name
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      className="mb-1"
                      placeholder="Enter Patient Name (Optional)"
                      value={patientName}
                      onChange={(e) => setPatientNme(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column style={{ marginTop: '1.3rem' }}>
                  </Form.Label>
                  <Col>
                    <Button onClick={() => handleSearch()} variant="outline-info" className="m-auto rounded btn-sm">Search</Button>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col className="mt-4 d-none d-md-block">
            <Button onClick={()=> history.push("/booking") } style={{ float: "right" }} variant="outline-success"  className="m-auto rounded btn-sm">Create</Button>
            {!loading && invoices ? (
              <ToolkitProvider
                keyField="id"
                data={invoices}
                columns={columns}
                loading={true}
                search
              >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        className="custom-card-boxshadow mt-2"
                        pagination={paginationFactory({
                          showTotal: true,
                        })}
                        {...props.baseProps}
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
            ) : null}
          </Col>
          <Col className="mt-4 d-md-none d-xs-block">
          <Button onClick={()=> history.push("/booking") } style={{ float: "right" }} variant="outline-success"  className="m-auto rounded btn-sm">Create</Button>
            {!loading && invoices ? (
              <ToolkitProvider
                keyField="id"
                data={invoices}
                columns={mobColumns}
                loading={true}
                search
              >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        className="custom-card-boxshadow mt-2"
                        pagination={paginationFactory({
                          showTotal: true,
                        })}
                        {...props.baseProps}
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
            ) : null}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Bookings;
