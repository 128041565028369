import axios from "axios";
import {
    ALL_TEST_REQUEST,
    ALL_TEST_SUCCESS,
    ALL_TEST_FAIL
  } from "../constants/testConstant";

export const getAllTests = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ALL_TEST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/v1/tests/list`, config);
    dispatch({
      type: ALL_TEST_SUCCESS,
      payload: data.data
    });
  } catch (error) {
    dispatch({
      type: ALL_TEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addNewTest = (testData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ALL_TEST_REQUEST,
    });

    const {
      userLogin: { userInfo },
      testList,
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.put(`/api/v1/tests/create`, testData, config)

    let tempData = testList.data;
    dispatch({
      type: ALL_TEST_SUCCESS,
      payload: [...tempData, testData]
    });
  } catch (error) {
    dispatch({
      type: ALL_TEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTest = (testData, objIndex) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ALL_TEST_REQUEST,
    });

    const {
      userLogin: { userInfo },
      testList,
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(`/api/v1/tests/edit`, testData, config)

    let tempTestData = testList.data
    tempTestData[objIndex] = testData

    dispatch({
      type: ALL_TEST_SUCCESS,
      payload: tempTestData
    });
  } catch (error) {
    dispatch({
      type: ALL_TEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteTest = (id, index) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ALL_TEST_REQUEST,
    });

    const {
      userLogin: { userInfo },
      testList,
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/v1/tests/delete/${id}`, config)

    let testData = testList.data
    testData.splice(index, 1);

    dispatch({
      type: ALL_TEST_SUCCESS,
      payload: testData
    });
  } catch (error) {
    dispatch({
      type: ALL_TEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

