import React, {useEffect} from "react";
import TopNavBar from "./components/TopNavBar";
import Sidebar from "./components/SideNavBar";
import LoginScreen from "./screens/LoginScreen";
import PrivateRoute from "./routing/privateRoute";
import ProfileScreen from "./screens/ProfileScreen";
import DashBoard from "./screens/DashBoard";
import { Col, Row } from "react-bootstrap";
import { Route, BrowserRouter as Router,  } from "react-router-dom";
import Billing from "./screens/Billing";
import DailyBook from "./screens/DailyBook";
import Tests from "./screens/Tests";
import Report from "./screens/Reports";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../src/actions/userActions";
import axios from "axios";
import Bookings from "./screens/Bookings";
import Booking from "./screens/Booking";

const App = () => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const dispatch = useDispatch();
  
  useEffect(() => {
    if(window.location.pathname !== '/login') {
      try {
          const config = {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userInfo.token}`,
              },
          };
          axios.get(`/api/v1/users/validToken`, config).catch(() => dispatch(logout()))
      } catch (error) {
          dispatch(logout());
      }
    }
  }, [])

  return (
    <Router>
      <TopNavBar />
      <Row className="w-100 " style={{height:"100vh"}}>
        <>
          <Sidebar />
        </>
        <Col md={10} className="custom-page mx-auto">
          <Route path="/login" component={LoginScreen} />

          {/* private routes */}
          <PrivateRoute path="/" exact component={Billing} />
          <PrivateRoute path="/profile" component={ProfileScreen} />
          <PrivateRoute path="/dashboard"  component={DashBoard} />
          <PrivateRoute path="/billing"  component={Billing} />
          <PrivateRoute path="/daily-book"  component={DailyBook}/>
          <PrivateRoute path="/tests"  component={Tests}/>
          <PrivateRoute path="/reports"  component={Report}/>
          <PrivateRoute path="/booking"  component={Booking}/>
          <PrivateRoute path="/bookings"  component={Bookings}/>
        </Col>
      </Row>
    </Router>
  );
};

export default App;
