import {
    ALL_TEST_REQUEST,
    ALL_TEST_SUCCESS,
    ALL_TEST_FAIL,
  } from "../constants/testConstant";
  
  //reducer to get all tests
  export const allTestLists = (state = {}, action) => {
    switch (action.type) {
      case ALL_TEST_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case ALL_TEST_SUCCESS:
        return {
          loading: false,
          data: action.payload
        };
  
      case ALL_TEST_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };