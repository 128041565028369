import {
    ADD_NEW_TRANSACTION_REQUEST,
    ADD_NEW_TRANSACTION_FAIL,
    GET_ALL_TRANSACTION_REQUEST,
    GET_ALL_TRANSACTION_SUCCESS,
    GET_ALL_TRANSACTION_FAIL,
  } from "../constants/dailyConstant";
  
  
  export const addNewTransactionReducer = (state = {}, action) => {
    switch (action.type) {
      case ADD_NEW_TRANSACTION_REQUEST:
        return {
          loading: true,
        };
  
      case ADD_NEW_TRANSACTION_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  

  
  //reducer to get all user details
  export const allTransactionDetails = (state = { transactions: {} }, action) => {
    switch (action.type) {
      case GET_ALL_TRANSACTION_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case GET_ALL_TRANSACTION_SUCCESS:
        return {
          loading: false,
          data: action.payload.data,
          opening_balance: action.payload.opening_balance ? action.payload.opening_balance : 0,
          pending_balance: action.payload.pending_balance ? action.payload.pending_balance : 0,
        };
  
      case GET_ALL_TRANSACTION_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  // export const userUpdateReducer = (state = {}, action) => {
  //   switch (action.type) {
  //     case USER_UPDATE_PROFILE_REQUEST:
  //       return {
  //         loading: true,
  //       };
  
  //     case USER_UPDATE_PROFILE_SUCCESS:
  //       return {
  //         loading: false,
  //         success: true,
  //         userInfo: action.payload,
  //       };
  
  //     case USER_UPDATE_PROFILE_FAIL:
  //       return {
  //         loading: false,
  //         error: action.payload,
  //       };
  
  //     case USER_UPDATE_PROFILE_RESET:
  //       return {};
  
  //     default:
  //       return state;
  //   }
  // };
  