import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import propsResolver from "react-bootstrap-table-next/lib/src/props-resolver";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllData, addNewTransaction, updateTransaction, deleteTransaction } from "../actions/dailyActions";

const format_rupees = (val) => {
  return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0 }).format(val);
}

const DailyBook = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [particulars, setParticulars] = useState("");
  const [amount, setAmount] = useState("");
  const [transactionType, setTransactionType] = useState("Debit");
  const [transactionData, setTransactionData] = useState("")
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transactions.data);
  const openingBalance = useSelector((state) => state.transactions.opening_balance);
  const pendingBalance = useSelector((state) => state.transactions.pending_balance);
  const loading = useSelector((state) => state.transactions.loading);
  const [balance, setBalance] = useState({totalCredit: 0, totalDebit: 0, balance: 0})

  useLayoutEffect(() => {
    handleSearch()
  }, [])

  const handleSearch = () => {
    let filters = {
      start_date: startDate,
      end_date: endDate
    }
    dispatch(getAllData(filters))
  }

  const clearFields = () => {
    setTransactionDate(new Date());
    setParticulars("");
    setAmount("");
    setTransactionType("Debit");
  };

  const handleClose = () => {
    clearFields();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleSave = () => {
    let data = {
      transacion_date: transactionDate,
      description: particulars,
      amount,
      type: transactionType
    };
    //Add new transaction api call
    dispatch(addNewTransaction(data))
    clearFields();
    setShow(false);
    handleSearch()
  };

  const editTransentry = (val, index) => {
    setTransactionDate(new Date(val.transacion_date));
    setParticulars(val.description);
    setAmount(val.amount);
    setTransactionType(val.type)
    setTransactionData({transacID: val.id,  index: index})
    setShow(true);
  }

  const updateTransentry = () => {
    let data = {
      id: transactionData.transacID,
      transacion_date: transactionDate,
      description: particulars,
      amount,
      type: transactionType
    };
    dispatch(updateTransaction(data, transactionData.index))
    clearFields();
    setShow(false);
    handleSearch()
  }

  const deleteTransentry = (e, id, index) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this entry?")) {
      dispatch(deleteTransaction(id, index));
      handleSearch();
    }
  }

  useEffect(() => {
    if(!loading && transactions) {
      let totalCredit = 0;
      let totalDebit = 0;
      transactions.forEach(element => {
        if(element.type === "Credit") {
          totalCredit += Math.abs(element.amount)
        }
        if(element.type === "Debit") {
          totalDebit += Math.abs(element.amount)
        }
      });
      let balance = (openingBalance + totalCredit) - totalDebit;
      setBalance({totalCredit: totalCredit, totalDebit: totalDebit, balance: balance})
    }
  }, [loading])

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const enableEdit = (value) => {
    if(new Date().toDateString() !== new Date(value.transacion_date).toDateString() || value.invoice_no) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Card className="p-2 py-4">
        <Row className="w-100 m-auto">
          <h3 className="w-100 text-center">Day Book</h3>
          <Col md={12} className="mb-3">
            <Row className="mx-auto">
              <Col>
                <Card className="py-3 mt-4 text-center custom-bg-green custom-card-boxshadow">
                  <Card.Title className="m-0">Opening Balance: {format_rupees(openingBalance)}</Card.Title>
                </Card>
              </Col>
              <Col>
                <Card className="py-3 mt-4 text-center custom-bg-green custom-card-boxshadow">
                  <Card.Title className="m-0">Closing Balance: {format_rupees(balance.balance)}</Card.Title>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={12} className="mb-4">
            <Row className="mx-auto">
              <Col>
                <Card className="p-3 text-center custom-bg-green custom-card-boxshadow">
                  <Card.Title className="m-0">Received: {format_rupees(balance.totalCredit)}</Card.Title>
                </Card>
              </Col>
              <Col>
                <Card className="p-3 text-center custom-bg-green custom-card-boxshadow">
                  <Card.Title className="m-0">Payment: {format_rupees(balance.totalDebit)}</Card.Title>
                </Card>
              </Col>
              <Col>
                <Card className="p-3 text-center custom-bg-green custom-card-boxshadow">
                  <Card.Title className="m-0">Pending: {format_rupees(pendingBalance)}</Card.Title>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Row className="mx-auto">
              <Col md="4">
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label>
                    Date Between
                  </Form.Label>
                  <DatePicker
                    placeholderText="Select Date Range"
                    className="form-control mb-1"
                    selected={startDate}
                    onChange={onDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Button onClick={() => handleSearch()} variant="outline-info" style={{ marginTop: '2.1rem' }} className="rounded btn-sm">Search</Button>
              </Col>
              <Col>
              <Button onClick={handleShow} size="sm" variant="success" style={{float: 'right', marginTop: '2.1rem'}}>
                Add New Transaction
              </Button>
              </Col>
            </Row>
          </Col>
          <Col className="mt-2">
            <>
              <Table
                className="custom-card-boxshadow mt-2"
                responsive
                hover
                size="sm"
                bordered
              >
                <thead>
                  <tr>
                    <th className="text-center" width="150">Date</th>
                    <th>Particulars</th>
                    <th className="text-right">Debit</th>
                    <th className="text-right">Credit</th>
                    <th width="150" className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && transactions && transactions.map((val, index) => {
                    return (
                    <tr key={index}>
                    <td className="text-center">{new Date(val.transacion_date).toLocaleDateString("en-IN")}</td>
                    <td>{val.description}</td>
                    {val.type === "Debit" ? (
                      <td className="text-danger text-right">{format_rupees(val.amount)}</td>
                    ) : (
                      <td className="text-right">-</td>
                    )}
                    {val.type === "Credit" ? (
                      <td className="text-success text-right">{format_rupees(val.amount)}</td>
                    ) : (
                      <td className="text-right">-</td>
                    )}
                    <td className="text-center">
                      {!enableEdit(val) ? (
                        <>
                          <Button disabled={enableEdit(val)} title="Edit Entry" variant="link" size="sm" className="mr-2" onClick={() => editTransentry(val, index)}>
                            <i className="fa text-warning fa-pencil fa-2x"></i>
                          </Button>{" "}
                          <Button disabled={enableEdit(val)} title="Delete Entry" variant="link" size="sm" className="mr-2" onClick={(e) => deleteTransentry(e, val.id, index)}>
                            <i className="fa text-danger fa-trash fa-2x"></i>
                          </Button>
                        </>
                      ) : (
                        <>
                         -
                        </>
                      )}
                    </td>
                  </tr>
                    )
                  })}
                </tbody>
              </Table>
            </>
          </Col>
        </Row>
      </Card>
      <Modal className="mt-5" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{transactionData && transactionData.transacID ? 'Edit' : 'Add new'} Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <DatePicker
                placeholderText="Date"
                className="form-control mb-1"
                selected={transactionDate}
                onChange={(date) => setTransactionDate(date)}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
              />
            </Col>
            <Col md={12}>
              <Form.Control
                type="text"
                className="mb-1"
                placeholder="Particulars"
                value={particulars}
                onChange={(e) => setParticulars(e.target.value)}
              />
            </Col>
            <Col md={12}>
              <Form.Control
                value={amount}
                type="text"
                className="mb-1"
                placeholder="Amount"
                onChange={(e) => setAmount(e.target.value)}
              />
            </Col>
            <Col md={12}>
              <Row className="mt-3">
                <Col md="6">
                  <Card
                    onClick={() => setTransactionType("Debit")}
                    className={`${
                      transactionType === "Debit" &&
                      "bg-danger text-white font-weight-bolder"
                    } p-2 border border-danger text-center cursor-pointer`}
                  >
                    Debit
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    onClick={() => setTransactionType("Credit")}
                    className={`${
                      transactionType === "Credit" &&
                      "bg-success text-white font-weight-bolder"
                    } p-2 border border-success text-center cursor-pointer`}
                  >
                    Credit
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={transactionData && transactionData.transacID ? updateTransentry : handleSave}
          disabled={particulars && amount && transactionType && transactionDate ? false : true}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DailyBook;
