import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { allUserDetailsReducer, userAddReducer, userDetailsReducer, userLoginReducer, userUpdateReducer } from './reducers/userReducer'
import {allTestLists} from './reducers/testReducer'
import {getAllInvoices} from './reducers/billingReducer'
import {allTransactionDetails} from './reducers/dailyBookReducer'

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userDetails:userDetailsReducer,
  updateUser:userUpdateReducer,
  addUser:userAddReducer,
  allUsers:allUserDetailsReducer,
  testList:allTestLists,
  billInfo:getAllInvoices,
  transactions: allTransactionDetails
})

const userInfoFromStorage = sessionStorage.getItem('userInfo')
  ? JSON.parse(sessionStorage.getItem('userInfo'))
  : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
