import React from "react";
import { Nav ,Col} from "react-bootstrap";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import "../dashboard.css";
import { LinkContainer } from "react-router-bootstrap";

const Side = (props) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <>
      {userInfo && (
        <Col md={2} className="d-none d-md-block custom-sidebar-container pr-0">
          <Nav variant="pills" className="h-100 d-none d-md-block sidebar" id="sideNav">
            <div><Nav.Item className="m-0 mt-3">
              <LinkContainer to="/daily-book">
                <Nav.Link>Day Book</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item className="m-0 mt-3">
              <LinkContainer to="/billing">
                <Nav.Link>TRF</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item className="m-0 mt-3">
              <LinkContainer to="/bookings">
                <Nav.Link>Bookings</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item className="m-0 mt-3">
              <LinkContainer to="/reports">
                <Nav.Link>Reports</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            {!userInfo.email.includes("labinvoices.com") ? (
              <Nav.Item className="m-0 mt-3">
                <LinkContainer to="/tests">
                  <Nav.Link>tests</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            ) : null}
            </div>
          </Nav>
        </Col>
      )}
    </>
  );
};

const Sidebar = withRouter(Side);
export default Sidebar;
