import axios from "axios";
import {
    ADD_NEW_TRANSACTION_REQUEST,
    ADD_NEW_TRANSACTION_FAIL,
    GET_ALL_TRANSACTION_REQUEST,
    GET_ALL_TRANSACTION_SUCCESS,
    GET_ALL_TRANSACTION_FAIL,
  } from "../constants/dailyConstant";


export const addNewTransaction = (transData) => async (dispatch,getState) => {
  try {
    dispatch({
      type: ADD_NEW_TRANSACTION_REQUEST,
    });

    const {
      userLogin: { userInfo },
      transactions
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(
      "/api/v1/transacton/create",
      transData,
      config
    );

    let tempData = transactions.data

    dispatch({
      type: GET_ALL_TRANSACTION_SUCCESS,
      payload: [...tempData, transData],
    });
  } catch (error) {
    dispatch({
      type: ADD_NEW_TRANSACTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllData = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_TRANSACTION_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/v1/transacton/list`, filters, config);
    dispatch({
      type: GET_ALL_TRANSACTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_TRANSACTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTransaction = (transData, objIndex) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_TRANSACTION_REQUEST,
    });

    const {
      userLogin: { userInfo },
      transactions,
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(`/api/v1/transacton/edit`, transData, config)

    let tempData = transactions.data
    tempData[objIndex] = transData

    dispatch({
      type: GET_ALL_TRANSACTION_SUCCESS,
      payload: tempData
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_TRANSACTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteTransaction = (id, index) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_TRANSACTION_REQUEST,
    });

    const {
      userLogin: { userInfo },
      transactions,
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/v1/transacton/delete/${id}`, config)

    let tempData = transactions.data
    tempData.splice(index, 1);

    dispatch({
      type: GET_ALL_TRANSACTION_SUCCESS,
      payload: tempData
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_TRANSACTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};