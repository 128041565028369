import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch, { fuzzySearch } from "react-select-search";
import axios from "axios";
import { useSelector } from "react-redux";
import "../dashboard.css";
import { useHistory } from "react-router-dom";
const crypto = require("crypto");
const regexNum = /^\d+$/
const regexText = /^(\w+\s?)*\s*$/;
let oldSearchValue;
let testsList =[]
let stateEdit = false
let dollarIndianLocale = Intl.NumberFormat('en-IN');
let listTestCodes = [];
let isBooking = false;
let paramId = "";

const subName = ["Mr.", "Mrs.", "Miss.", "Dr/Mr.", "Dr/Mrs.", "Dr/Miss.", "Others.", "Baby.", "Master."];

const format_rupees = (val) => {
  return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(val);
}

const Billing = () => {
  const history = useHistory();
  const userInfo = useSelector(state => state.userLogin.userInfo)
  const [invoiceNo, setinvoiceNo] = useState(null)
  const [billDate, setBillDate] = useState(new Date());
  const [patientSubName, setPatientSubNme] = useState("");
  const [patientName, setPatientNme] = useState("");
  const [refDoc, setRefDoc] = useState("");
  const [ptID, setPTID] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [paymentType, setPaymentType] = useState("Cash");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [ageYear, setAgeYear] = useState("");
  const [ageMonth, setAgeMonth] = useState("");
  const [trfType, setTrfType] = useState("No");

  const [total,setTotal] = useState(0);
  const [less,setLess] = useState(0);
  const [netAmount,setNetAmount] = useState(0);
  const [advance,setAdvance] = useState(0);
  const [balancePayable,setBalancePayable] = useState(0)
  const [billingJson, setBillingJson] = useState([
    {
      description: "",
      amount: "",
    },
  ]);
  const [testOptions, setTestOptions] = useState([])
  const [isError ,setIsError] = useState(false);
  const [mobilePop, setMobilePop] = useState(false);
  const [oldRecords, setoldRecords] = useState([]);
  const [columnSpan, setColumnSpan] = useState(2);

  const checkInvoiceNumber = async () => {
    let nextInvoice =  crypto.randomBytes(4).toString("hex");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.get(`/api/v1/bill/checkBillNo/${nextInvoice}`, config).then(res => {
      if(res.data.valid) {
        setinvoiceNo(nextInvoice)
      } else {
        checkInvoiceNumber()
      }
    })
    .catch(() => {})
  }

  const getDatafromInvoice = async (invId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    let endpoint = isBooking ? 'booking/view' : 'bill/viewInvoice'
    await axios.get(`/api/v1/${endpoint}/${invId}`, config).then(res => {
      if(res.data.data) {
        stateEdit = isBooking ? false : true
        const invData = res.data.data;
        const patName = invData.patient_name.includes('.') ? invData.patient_name.split('.') : invData.patient_name
        !isBooking && setinvoiceNo(invData.invoice_no);
        setPatientSubNme(invData.patient_name.includes('.') ? patName[0] + '.' : '')
        setPatientNme(invData.patient_name.includes('.') ? patName[1] : invData.patient_name)
        setBillDate(new Date(isBooking ? invData.booking_date : invData.invoice_date))
        setAge(invData.patient_age)
        setGender(invData.patient_gender)
        setRefDoc(invData.reference_doctor)
        setMobileNumber(invData.patient_mobile)
        setPTID(invData.pt_id)
        setEmail(invData.patient_email)
        setPaymentType(invData.payment_type)
        setAdvance(invData.advance)
        setLess(invData.discounts)
        setNetAmount(invData.net_amount)
        setTotal(Math.floor(invData.net_amount + invData.discounts))
        setBalancePayable(Math.floor(invData.net_amount - invData.advance))
        setBillingJson(JSON.parse(invData.items))
        let fullage = invData.patient_age.includes('.') ? invData.patient_age.split('.') : null
        if(fullage) {
          setAgeYear(fullage[0]);
          setAgeMonth(fullage[1]);
        } else {
          setAgeYear(invData.patient_age);
        }
        let tempItems = JSON.parse(invData.items)
        tempItems.forEach(element => {
          let tempValue = element.description.split(" - ")
          listTestCodes.push(tempValue[0]);
        });
      } else{
        // alert("No data found");
        // window.location.reload();
      }
    })
    .catch(() => {
      // alert("No data found");
      // window.location.reload();
    })
  }

  const seacrhMobileData = async (mobile) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(`/api/v1/bill/checkMobileNumber`, {mobile_number: mobile}, config).then(res => {
      if(res.data.data && res.data.data.length > 0) {
        setoldRecords(res.data.data);
        setMobilePop(true);
      }
    })
    .catch(() => {})
  }

  useEffect(() => {
    const getStyles = document.querySelector('.testSpan');
    setColumnSpan(window.getComputedStyle(getStyles).columnCount);
  }, [])

  useEffect(() => {
    if(window.location.search !== "" && (window.location.search.includes("trfNo") || window.location.search.includes("id"))) {
      if(window.location.search.includes("id")) {
        checkInvoiceNumber();
        isBooking = true;
      }
      const query = window.location.search.split("=")[1]
      paramId = query;
      getDatafromInvoice(query)
    } else {
      if(stateEdit) window.location.reload();
      checkInvoiceNumber();
      stateEdit = false;
      isBooking = false;
      paramId = "";
    }
  }, [window.location.search])

  useEffect(() => {
    let arrObj = billingJson;
    let sumObj = arrObj.reduce(function (a, b) {
      return {
        amount: parseInt(a.amount ? a.amount : 0 ) + parseInt(b.amount ? b.amount : 0),
      };
    });
      sumObj.amount = sumObj.amount > 0 ? sumObj.amount : 0
      setTotal(parseInt(sumObj.amount))
      setNetAmount(parseInt(sumObj.amount)-less);
      setBalancePayable(parseInt(sumObj.amount)-less-advance)
  }, [billingJson.length])

  const changeBillingDescription = (value, index) => {
    let tempValue = value.split(" - ")
    let testItem = testsList.find(({test_code}) => test_code === tempValue[0]);
    let arrObj = billingJson;
    arrObj[index].description = value;
    setBillingJson(arrObj);
    handleChangeAmount(testItem.test_price, index)
    listTestCodes.push(tempValue[0]);
  };

  const searchBillingDescription = async (value) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    if(value && value.length > 2 && value !== oldSearchValue) {
      setTestOptions([])
      await axios.get(`/api/v1/tests/list/${value}`, config).then(res => {
        oldSearchValue = value;
          if(res.data.data) {
            testsList = res.data.data;
          let options = res.data.data.map((o1) => {
            if(o1.test_code) {
              return {name: o1.test_code + ' - ' + o1.test_name, value: o1.test_code + ' - ' + o1.test_name}
            }
          })
          setTestOptions(options)
        }
      }).catch(() => {})
    }
  }

  const handleChangeAmount = (value,index) => {
    if(!isNaN(value)){
      value !== "" ? value = +value : value = 0;
      let arrObj = billingJson;
    arrObj[index].amount = value;
    setBillingJson(arrObj);
    
    let sumObj = arrObj.reduce(function (a, b) {
      return {
        amount: parseInt(a.amount) + parseInt(b.amount),
      };
    });
      sumObj.amount = sumObj.amount > 0 ? sumObj.amount : 0
      setTotal(parseInt(sumObj.amount))
      setNetAmount(parseInt(sumObj.amount)-less);
      setBalancePayable(parseInt(sumObj.amount)-less-advance)
    }
  }

  const addNewLine = () => {
    console.log(billingJson);
    let arrayJson = billingJson;
    let newArray = {
      description: "",
      amount: "",
    };
    setBillingJson([...arrayJson, newArray]);
  };

  const deleteLine = (index) => {
    setBillingJson((prev) => prev.filter((el, i) => i !== index));
  };

  const handleSubmit = async () => {
    if(patientSubName && patientName && age && billDate && gender && mobileNumber){
      let data = {
        invoiceNo,
        invoice_date: billDate,
        patient_name: patientSubName + ' ' + patientName,
        patient_age: age,
        patient_gender: gender,
        patient_mobile: mobileNumber,
        patient_email: email,
        paymentType,
        reference_doctor: refDoc,
        pt_id: ptID,
        items: billingJson,
        discount: less,
        advance: advance,
        net_amount: netAmount
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      let endpoint = stateEdit ? 'update' : 'create'
      await axios.post(`/api/v1/bill/${endpoint}`, data, config)
      .then(res => {
        if(res.status === 200) {
          if(isBooking) {
            deleteBooking()
          }
          if(stateEdit) {
            if (window.confirm("Do you want to download updated invoice?")) {
              generatePdf()
            }
          } else {
            generatePdf()
          }
        } 
      })
      .catch(() => {})
    }else{
      setIsError(true)
      alert("Please enter all mandatory fields")
    }
  }

  const deleteBooking = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/v1/booking/delete/${paramId}`, config).catch(() => {})
  }

  const changeLessAmount = (e) => {
    if(e.target.value !== ""){
      if(e.target.value <= total && regexNum.test(e.target.value)){
        e.target.value = + e.target.value
        setNetAmount(total - parseInt(e.target.value));
        let payable = total-parseInt(e.target.value)-advance
        payable = payable < 0 ? 0 : payable
        setBalancePayable(payable)
        setLess(e.target.value)
      }
    }else{
      setNetAmount(total);
      setBalancePayable(total - advance)
      setLess(0);
    }
  }

  const changeAdvanceAmount = (e) => {
    if(e.target.value !== ""){
      if(e.target.value <= netAmount && regexNum.test(e.target.value)){
        e.target.value = + e.target.value
        setBalancePayable(total-less-parseInt(e.target.value))
        setAdvance(e.target.value)
      }
    }else{
      setBalancePayable(netAmount)
      setAdvance(0);
    }
  }

  const generatePdf = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.get(`/api/v1/bill/generatePdf/${invoiceNo + "&" + trfType}`, config).then(res => {
      if(res.data.pdf) {
        var inLink = document.createElement("a");
        inLink.download = invoiceNo + ".pdf";
        inLink.href = "data:application/octet-stream;base64," + res.data.pdf;
        document.body.appendChild(inLink);
        inLink.click();
        document.body.removeChild(inLink);
        if(!stateEdit) {
          history.push('/billing')
          window.location.reload();
        }
      }
    }).catch(() => {})
  }

  const filterTestList = (test, val) => {
    let check = test.find(({name}) => name === val)
    if(!check && val) {
      return [...test, {name: val, value: val}]
    } else {
      return test
    }
  }

  const updateOldDetails = (invData) => {
    const patName = invData.patient_name.includes('.') ? invData.patient_name.split('.') : invData.patient_name;
    setPatientSubNme(invData.patient_name.includes('.') ? patName[0] + '.' : '');
    setPatientNme(invData.patient_name.includes('.') ? patName[1] : invData.patient_name);
    setAge(invData.patient_age);
    setGender(invData.patient_gender);
    setRefDoc(invData.reference_doctor);
    setMobileNumber(invData.patient_mobile);
    setEmail(invData.patient_email);
    setMobilePop(false);
    let fullage = invData.patient_age.includes('.') ? invData.patient_age.split('.') : null
    if(fullage) {
      setAgeYear(fullage[0]);
      setAgeMonth(fullage[1]);
    } else {
      setAgeYear(invData.patient_age);
    }
  }

  const updatePatientSubNme = (e) => {
    setPatientSubNme(e.target.value);
    if(e.target.value == "Mr." || e.target.value == "Dr/Mr.") {
      setGender("Male");
    } else if(e.target.value == "Mrs." || e.target.value == "Miss." || e.target.value == "Dr/Mrs." || e.target.value == "Dr/Miss.") { 
      setGender("Female");
    } else {
      setGender("");
    }
  }

  const copyBarcode = async () => {
    if(!navigator) return;
    try {
      if(listTestCodes.length > 0) {
        await navigator.clipboard.writeText(listTestCodes);
        alert('Test codes copied to clipboard');
      }
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  return (
    <>
      <Card className="p-2 py-4 custom-top-boxshadow h-100">
        <Row className="w-100">
          <h3 className="w-100 text-center">Test Requisition Form (TRF)</h3>
          <Col md={12}>
            <Row className="">
              <Col md={6}>
                {/* <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3">
                    TRF No.
                  </Form.Label>
                  <Col md="8" sm="12">
                    <Form.Control type="text" placeholder="TRF No." value ={invoiceNo} readOnly />
                  </Col>
                </Form.Group> */}
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3">
                    Mobile Number
                  </Form.Label>
                  <Col md="8" sm="12">
                    <Form.Control maxLength={12} 
                    className={isError && !mobileNumber && "border border-danger"} 
                    autoComplete="off" value={mobileNumber} 
                    onChange={(e) => { 
                      !e.target.value && setMobileNumber(e.target.value)
                      if(regexNum.test(e.target.value.replaceAll(/\s/g,'')))
                          setMobileNumber(e.target.value.replaceAll(/\s/g,''))
                      if(e.target.value && e.target.value.length > 9) {
                        if(regexNum.test(e.target.value.replaceAll(/\s/g,'')))
                          seacrhMobileData(e.target.value.replaceAll(/\s/g,''))
                      }
                    }}
                    type={"tel"} placeholder="Mobile No." />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3">
                    Date & Time
                  </Form.Label>
                  <Col md="8" sm="12">
                    <DatePicker
                      placeholderText="Date"
                      className={`form-control ${isError && !billDate && "border border-danger"}`}
                      selected={billDate}
                      onChange={(date) => setBillDate(date)}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy h:mm aa"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column md="3" sm="12" xs="12">
                    Patient Name
                  </Form.Label>
                  <Col md="2" sm="4" xs="4" style={{paddingRight: '5px'}}>
                  <Form.Control className={isError && !patientSubName && "border border-danger"} style={{padding: '0px', textAlign: 'center'}} as="select" autoComplete="off" value={patientSubName} onChange={(e)=> updatePatientSubNme(e)}>
                    <option hidden></option>
                    {subName.map((o1) => {
                      return (
                        <option value={o1}>{o1}</option>
                      )
                    })}
                  </Form.Control>
                  </Col>
                  <Col md="6" sm="8" xs="8" style={{paddingLeft: '5px'}}>
                    <Form.Control className={isError && !patientName && "border border-danger"} autoComplete="off" value={patientName} onChange={(e) => {
                      !e.target.value && setPatientNme("")
                      if(regexText.test(e.target.value)){
                        setPatientNme(e.target.value)
                      }
                      }} type="text" placeholder="Patient Name." 
                      onKeyDown={event => {
                        if (event.key == ".") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column md="3" xs="12">
                    Age
                  </Form.Label>
                  <Col md="4" xs="6">
                    <Form.Control maxLength={2}
                    className={isError && !age && "border border-danger"} 
                    autoComplete="off" value ={ageYear} 
                    onChange={(e) => { 
                      !e.target.value && setAgeYear(e.target.value)
                      if(regexNum.test(e.target.value))
                          setAgeYear(e.target.value)
                          if(ageMonth != "") {
                            setAge(e.target.value + "." + ageMonth)
                          } else {
                            setAge(e.target.value)
                          }
                      }} 
                    type={"tel"} placeholder="Y" />
                  </Col>
                  <Col md="4" xs="6">
                    <Form.Control maxLength={2}
                    className={isError && !age && "border border-danger"} 
                    autoComplete="off" value ={ageMonth} 
                    onChange={(e) => { 
                      !e.target.value && setAgeMonth(e.target.value)
                      if(regexNum.test(e.target.value))
                          setAgeMonth(e.target.value)
                          if(ageYear != "") {
                            setAge(ageYear + "." + e.target.value)
                          } else {
                            setAge("0." + e.target.value)
                          }
                      }} 
                    type={"tel"} placeholder="M" />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3">
                    Gender
                  </Form.Label>
                  <Col md="8" sm="12">
                    <div className="d-flex mt-2">
                      <Form.Check
                        onChange={() => setGender("Male")}
                        type={"radio"}
                        label="Male"
                        name="gender"
                        className={`mr-3`}
                        checked={gender === "Male"}
                        id="Male"
                      />
                      <Form.Check
                        onChange={() => setGender("Female")}
                        type={"radio"}
                        label="Female"
                        name="gender"
                        className="mr-3"
                        checked={gender === "Female"}
                        id="Female"

                      />
                      <Form.Check
                        onChange={() => setGender("Other")}
                        type={"radio"}
                        label="Other"
                        name="gender"
                        className="mr-3"
                        checked={gender === "Other"}
                        id="Other"

                      />
                      
                    </div>
                    {isError && !gender && <sup className="text-danger">Please select gender</sup>}
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3">
                    Ref Dr
                  </Form.Label>
                  <Col md="8" sm="12">
                    <Form.Control autoComplete="off" value={refDoc} onChange={(e)=>setRefDoc(e.target.value)} type="text" placeholder="Ref. Dr" />
                  </Col>
                </Form.Group>
              </Col>
              {/* <Col md={6}>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3">
                    Mobile Number
                  </Form.Label>
                  <Col md="8" sm="12">
                    <Form.Control maxLength={10} 
                    className={isError && !mobileNumber && "border border-danger"} 
                    autoComplete="off" value={mobileNumber} 
                    onChange={(e) => { 
                      !e.target.value && setMobileNumber(e.target.value)
                      if(regexNum.test(e.target.value))
                          setMobileNumber(e.target.value)
                      if(e.target.value && e.target.value.length > 9) {
                        if(regexNum.test(e.target.value))
                          seacrhMobileData(e.target.value)
                      }
                    }}
                    type="text" placeholder="Mobile No." />
                  </Col>
                </Form.Group>
              </Col> */}
              <Col md={6}>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3">
                    Email
                  </Form.Label>
                  <Col md="8" sm="12">
                    <Form.Control autoComplete="off" value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="Email" />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3">
                    Remarks
                  </Form.Label>
                  <Col md="8" sm="12">
                    <Form.Control autoComplete="off" value={ptID} onChange={(e)=>setPTID(e.target.value)} type="text" placeholder="Remarks" />
                  </Col>
                </Form.Group>
              </Col>
              
              <Col md={6}>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3">
                    Payment Type
                  </Form.Label>
                  <Col md="8" sm="12">
                    <div className="d-flex mt-2">
                      <Form.Check
                        onChange={() => setPaymentType("Cash")}
                        type={"radio"}
                        label="Cash"
                        name="paymentType"
                        className="mr-3"
                        checked={paymentType === "Cash"}
                        id="Cash"

                      />
                      <Form.Check
                        onChange={() => setPaymentType("Card")}
                        type={"radio"}
                        label="Card"
                        name="paymentType"
                        className="mr-3"
                        checked={paymentType === "Card"}
                        id="Card"

                      />
                      <Form.Check
                        onChange={() => setPaymentType("UPI")}
                        type={"radio"}
                        label="UPI"
                        name="paymentType"
                        className="mr-3"
                        checked={paymentType === "UPI"}
                        id="UPI"

                      />
                    </div>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3">
                    Note
                  </Form.Label>
                  <Col md="8" sm="12">
                    <Form.Control autoComplete="off" value={trfType} onChange={(e)=>setTrfType(e.target.value)} type="text" placeholder="" />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col className="mt-4">
            <>
              <Table responsive hover size="sm" bordered className="h-100">
                <thead>
                  <tr>
                    <th className="text-center billingSl">Sl.no</th>
                    <th>Name of the Test</th>
                    <th className="billingAm">Amount</th>
                    <th className="billingAc">Action</th>
                  </tr>
                </thead>
                <tbody>
                {billingJson &&
                  billingJson.map((val, i) => (
                    <tr key={i}>
                      <td className=" text-center billingSl">{i + 1}</td>
                      <td className="p-0 px-2">
                        <SelectSearch
                          filterOptions={fuzzySearch}
                          search
                          autoComplete="on"
                          options={filterTestList(testOptions, val.description)}
                          value={billingJson[i].description}
                          name="test_name"
                          placeholder="Enter Test Name"
                          getOptions={(val) => searchBillingDescription(val)}
                          onChange={(e) => changeBillingDescription(e, i)}
                          emptyMessage="No Test Found"
                        />
                      </td>
                      <td className="p-0 px-2 billingAm">
                        <Form.Control
                          type="tel"
                          placeholder="Enter amount"
                          value={billingJson[i].amount}
                          onChange={(e)=> handleChangeAmount(e.target.value,i)}
                        ></Form.Control>
                      </td>
                      <td className="p-0">
                        {billingJson.length > 1 && (
                          <Button
                            variant="link"
                            size="sm"
                            className="mr-2"
                            onClick={() => deleteLine(i)}
                          >
                            <i className="fa text-danger fa-trash fa-2x"></i>
                          </Button>
                        )}

                        {billingJson.length === i + 1 && (
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => addNewLine()}
                            disabled={billingJson[i].description === "" || billingJson[i].amount === 0 }
                          >
                            <i className={`fa text-success fa-plus fa-2x ${(billingJson[i].description === "" || billingJson[i].amount === 0) && "text-secondary" }`}></i>
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={columnSpan * 2} className="testSpan p-0"><hr className="mt-3" /></td>
                  </tr>
                  <tr>
                    <td colSpan={columnSpan} className="text-right font-weight-bold p-1">Total</td>
                    <td colSpan={columnSpan} className="font-weight-bold p-1">{format_rupees(total)}</td>
                  </tr>
                  <tr>
                    <td colSpan={columnSpan} className="text-right font-weight-bold p-1">Less</td>
                    <td colSpan={columnSpan} className="font-weight-bold p-1">
                    <input className="p-0 custom-focus w-100" style={{border:"none"}} type="text" value={less} onChange={(e)=>changeLessAmount(e)}/></td>
                  </tr>
                  <tr>
                    <td colSpan={columnSpan} className="text-right font-weight-bold p-1">Net Amount</td>
                    <td colSpan={columnSpan} className="font-weight-bold p-1">{format_rupees(netAmount)}</td>
                  </tr>
                  <tr>
                    <td colSpan={columnSpan} className="text-right font-weight-bold p-1">Advance</td>
                    <td colSpan={columnSpan} className="font-weight-bold p-1">
                    <input className="p-0 custom-focus w-100" style={{border:"none"}} type="text" value={advance} onChange={(e)=>changeAdvanceAmount(e)}/></td>
                  </tr>
                  <tr>
                    <td colSpan={columnSpan} className="text-right font-weight-bold p-1">Balance Payable</td>
                    <td colSpan={columnSpan} className="font-weight-bold p-1">{format_rupees(balancePayable)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          </Col>
          <div className="w-100 d-flex justify-content-center">
            <button onClick={() => copyBarcode()} style={{marginLeft: '15px'}}>Copy Testcodes</button>
            <Button disabled={billingJson.length === 1 && (billingJson[0].description === "" || billingJson[0].amount === "" || billingJson[0].amount === 0 || billingJson[0].amount === "0")} onClick={()=>handleSubmit()} variant="success"  className="m-auto rounded">{stateEdit ? "Update" : "Generate"} TRF</Button>
          </div>
        </Row>
      </Card>
      <Modal className="mt-5" show={mobilePop} onHide={() => setMobilePop(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Old records found</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
            <Table
                className="custom-card"
                responsive
                hover
                size="sm"
              >
                <thead>
                  <tr>
                    <th>Patient Name</th>
                    <th>Age</th>
                    <th>Gender</th>
                  </tr>
                </thead>
                <tbody>
                  {oldRecords && oldRecords.map((o1, index) => {
                    return (
                      <tr key={index} onClick={() => updateOldDetails(o1)} style={{color: '#1f9bcf', cursor: 'pointer'}}>
                        <td>{o1.patient_name}</td>
                        <td>{o1.patient_age}</td>
                        <td>{o1.patient_gender}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Billing;
