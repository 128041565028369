import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../actions/userActions";

const TopNavBar = () => {
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header>
      {userInfo && (
        <Navbar
          className="custom-top-boxshadow"
          fixed="top"
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
        >
          <LinkContainer to="/">
            <Navbar.Brand>TR Forms</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="pl-5 ml-5 d-none d-md-block">
              <LinkContainer to="/billing">
                <Nav.Link>New TRF</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav className="ml-auto d-md-none d-xs-block" style={{float: 'left'}}>
              <LinkContainer to="/billing">
                <Nav.Link>TRF</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/reports" className="mb-1">
                <Nav.Link>Reports</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav className="ml-auto" style={{float: 'right'}}>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to="/profile" className="d-none d-md-block">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>Sign In</Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </header>
  );
};

export default TopNavBar;
