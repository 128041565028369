import {
    GET_ALL_BILL_REQUEST,
    GET_ALL_BILL_SUCCESS,
    GET_ALL_BILL_FAIL,
    GET_ALL_BOOKING_REQUEST,
    GET_ALL_BOOKING_SUCCESS,
    GET_ALL_BOOKING_FAIL
  } from "../constants/billingConstant";
  
  
  export const getAllInvoices = (state = {}, action) => {
    switch (action.type) {
      case GET_ALL_BILL_REQUEST:
        return {
          loading: true,
        };
  
      case GET_ALL_BILL_SUCCESS:
        return {
          loading: false,
          data: action.payload,
        };
  
      case GET_ALL_BILL_FAIL:
        return {
          loading: false,
          error: action.payload,
        };

      case GET_ALL_BOOKING_REQUEST:
        return {
          bookLoading: true,
        };
  
      case GET_ALL_BOOKING_SUCCESS:
        return {
          bookLoading: false,
          bookData: action.payload,
        };
  
      case GET_ALL_BOOKING_FAIL:
        return {
          bookLoading: false,
          bookError: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  