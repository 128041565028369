import React, { useLayoutEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTests,
  addNewTest,
  deleteTest,
  updateTest,
} from "../actions/testActions";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const format_rupees = (val) => {
  return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0 }).format(val);
}

const Tests = () => {
  const [show, setShow] = useState(false);
  const testList = useSelector((state) => state.testList.data);
  const loading = useSelector((state) => state.testList.loading);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [testCode, setTestCode] = useState("");
  const [testName, setTestName] = useState("");
  const [testPrice, setTestPrice] = useState("");
  const [testData, setTestData] = useState("");
  const dispatch = useDispatch();
  const { SearchBar } = Search;

  useLayoutEffect(() => {
    dispatch(getAllTests());
  }, []);

  const clearFields = () => {
    setTestCode("");
    setTestName("");
    setTestPrice("");
    setTestData("");
  };

  const handleClose = () => {
    clearFields();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleSave = () => {
    let data = {
      testCode,
      testName,
      testPrice,
    };
    //Add new transaction api call
    dispatch(addNewTest(data));
    clearFields();
    setShow(false);
  };

  const editTestentry = (val, index) => {
    setTestCode(val.test_code);
    setTestName(val.test_name);
    setTestPrice(val.test_price);
    setTestData({ testID: val.id, index: index });
    setShow(true);
  };

  const updateTestentry = () => {
    let data = {
      testID: testData.testID,
      testCode,
      testName,
      testPrice,
    };
    dispatch(updateTest(data, testData.index));
    clearFields();
    setShow(false);
    window.location.reload();
  };

  const deleteTestentry = (id, index) => {
    if (window.confirm("Are you sure you want to delete this test?")) {
      dispatch(deleteTest(id, index));
      window.location.reload();
    }
  };

  const columns = [
    {
      dataField: "test_code",
      text: "Test Code",
      sort: true,
      headerStyle: { width: '180px' }
    },
    {
      dataField: "test_name",
      text: "Test Name",
      sort: true,
    },
    {
      dataField: "test_price",
      text: "MRP",
      headerAlign: 'right',
      sort: true,
      headerStyle: { width: '150px' },
      style: { textAlign: 'right' },
      formatter: cell => format_rupees(cell)
    },
    {
      dataField: "id",
      text: "Action",
      editable: false,
      headerAlign: 'center',
      style: { textAlign: 'center' },
      headerStyle: { width: '150px' },
      searchable: false,
      formatter: (cellContent, row, index) => {
        return (
          <div>
            <Button
              variant="link"
              title="Edit Test"
              size="sm"
              className="py-0"
              onClick={() => { !userInfo.email.includes("labinvoices.com") && editTestentry(row, index)}}
            >
              <i className="fa text-warning fa-pencil fa-2x"></i>
            </Button>{" "}
            <Button
              variant="link"
              title="Delete Test"
              size="sm"
              className="py-0"
              onClick={() => { !userInfo.email.includes("labinvoices.com") && deleteTestentry(row.id, index)}}
            >
              <i className="fa text-danger fa-trash fa-2x"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  let validFields = testCode && testName && testPrice ? false : true;

  return (
    <>
      <Card className="p-2 py-4">
        <Row className="w-100 m-auto">
          <h3 className="w-100 text-center">All Tests</h3>
          <Col>
            <>
              {!userInfo.email.includes("labinvoices.com") ? (
                <Button
                  onClick={handleShow}
                  size="sm"
                  variant="success"
                  style={{ float: "right" }}
                  className="mb-2"
                >
                  Add New Test
                </Button>
              ) : null}
              {!loading && testList ? (
                <ToolkitProvider
                  keyField="id"
                  data={testList}
                  columns={columns}
                  loading={ true }
                  search
                >
                  {
                    props => (
                      <div>
                        <SearchBar { ...props.searchProps } />
                        <BootstrapTable
                          className="custom-card-boxshadow mt-2"
                          pagination={paginationFactory({
                            showTotal: true,
                          })}
                          { ...props.baseProps }
                        />
                      </div>
                    )
                  }
                </ToolkitProvider>
              ) : null}
            </>
          </Col>
        </Row>
      </Card>
      <Modal className="mt-5" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {testData && testData.testID ? "Edit" : "Add New"} Test
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Control
                type="text"
                className="mb-1"
                placeholder="Test Code"
                value={testCode}
                onChange={(e) => setTestCode(e.target.value)}
              />
            </Col>
            <Col md={12}>
              <Form.Control
                type="text"
                className="mb-1"
                placeholder="Test Name"
                value={testName}
                onChange={(e) => setTestName(e.target.value)}
              />
            </Col>
            <Col md={12}>
              <Form.Control
                value={testPrice}
                type="tel"
                className="mb-1"
                placeholder="Price"
                min={0}
                onChange={(e) => setTestPrice(e.target.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={validFields}
            variant="success"
            onClick={testData && testData.testID ? updateTestentry : handleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tests;
