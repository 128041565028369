import axios from "axios";
import {
    GET_ALL_BILL_REQUEST,
    GET_ALL_BILL_SUCCESS,
    GET_ALL_BILL_FAIL,
    GET_ALL_BOOKING_REQUEST,
    GET_ALL_BOOKING_SUCCESS,
    GET_ALL_BOOKING_FAIL
  } from "../constants/billingConstant";

export const getAllData = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_BILL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/v1/bill/billReport`, filters, config);
    dispatch({
      type: GET_ALL_BILL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BILL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getBookingData = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_BOOKING_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/v1/booking/all`, filters, config);
    dispatch({
      type: GET_ALL_BOOKING_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BOOKING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
