import React, { useState, useLayoutEffect, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Container,
  Card,
  Tab,
  Nav,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, updateUserProfile, changeUserPassword } from "../actions/userActions";

let regexNum = /^\d+$/
const ProfileScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  
  const updateUser = useSelector((state) => state.updateUser);
  const { success } = updateUser;
  const [address1, setAddress1] = useState("")
  const [address2, setAddress2] = useState("")
  const [address3, setAddress3] = useState("")
  const [contactNumber, setContactNumber] = useState("")
  const [landline, setLandline] = useState("")
  const [officeEmail, setofficeEmail] = useState("")
  const [newpass, setNewpass] = useState("")
  const [confirmPass, setConfirmPass] = useState("")
  const [validated, setValidated] = useState(false);



  useLayoutEffect(() => {
    dispatch(getUserDetails());
  }, [])

  useEffect(() => {
    setAddress1(user.address_line1 ? user.address_line1 : "")
    setAddress2(user.address_line2 ? user.address_line2 : "")
    setAddress3(user.address_line3 ? user.address_line3 : "")
    setContactNumber(user.contact_number ? user.contact_number : "")
    setLandline(user.landline ? user.landline : "")
    setofficeEmail(user.officeEmail ? user.officeEmail : "")
  }, [user])

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {
      address1,
      address2,
      address3,
      contactNumber,
      landline,
      officeEmail
    }
    dispatch(updateUserProfile(data));
  };

  const changePasswordHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      let data = {
        newPassword: newpass
      }
      dispatch(changeUserPassword(data));
    }
    setValidated(true);
  }

  return (
    <>
      <Container className="pt-5">
      <Card>
      <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
      <Row>
        <Col sm={3}>
          <Nav className="flex-column" id="profileNav">
            <Nav.Item>
              <Nav.Link eventKey="profile">Profile</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="changepass">Reset Password</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="profile">
              <Card.Body>
                <Card.Title className="text-center">Company Address</Card.Title>
                {error && <span style={{color: 'red'}} className="ml-4">{error}</span>}
                {success && <span style={{color: 'green'}} className="ml-4">{success}</span>}
                <Form onSubmit={submitHandler}>
                <Row>
                  <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>Address Line 1<span style={{color: 'red'}}>*</span> </Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter name"
                      value={address1}
                      maxLength={100}
                      onChange={(e) => setAddress1(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  </Col>
                  <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>Address Line 2</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter name"
                      value={address2}
                      maxLength={100}
                      onChange={(e) => setAddress2(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  </Col>
                  <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>Address Line 3</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter name"
                      value={address3}
                      maxLength={100}
                      onChange={(e) => setAddress3(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  </Col>
                  <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter mobile number"
                      value={contactNumber}
                      maxLength={11}
                      onChange={(e) => { 
                        if(e.target.value) { regexNum.test(e.target.value) && setContactNumber(e.target.value) } else setContactNumber("")}}
                    ></Form.Control>
                  </Form.Group>
                  </Col>
                  <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>Landline Number</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter landline number"
                      value={landline}
                      maxLength={11}
                      onChange={(e) => {
                        if(e.target.value) { regexNum.test(e.target.value) && setLandline(e.target.value) } else setLandline("")}}
                    ></Form.Control>
                  </Form.Group>
                  </Col>
                  <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>Office Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email address"
                      value={officeEmail}
                      maxLength={100}
                      onChange={(e) => setofficeEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  </Col>
                  </Row>
                  <Button type="submit" variant="success" disabled={address1 && address1.length > 10 ? false : true}>
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{" "}
                    Update
                  </Button>
                </Form>
              </Card.Body>
            </Tab.Pane>
            <Tab.Pane eventKey="changepass">
            <Card.Body>
                <Card.Title className="text-center">Reset Password</Card.Title>
                {error && <span style={{color: 'red'}} className="ml-4">{error}</span>}
                {success && <span style={{color: 'green'}} className="ml-4">{success}</span>}
                <Form onSubmit={changePasswordHandler} noValidate validated={validated}>
                <Row>
                  <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      value={newpass}
                      minLength={8}
                      maxLength={20}
                      onChange={(e) => setNewpass(e.target.value)}
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid password.
                    </Form.Control.Feedback>
                  </Form.Group>
                  </Col>
                  <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Re enter password"
                      value={confirmPass}
                      maxLength={20}
                      minLength={8}
                      onChange={(e) => setConfirmPass(e.target.value)}
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Your new password and confirmation password do not match.
                    </Form.Control.Feedback>
                  </Form.Group>
                  </Col>
                  </Row>
                  <Button type="submit" variant="success" >
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{" "}
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
    </Card>
      </Container>
    </>
  );
};

export default ProfileScreen;
