import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const DashBoard = () => {
  return (
    <Row className="w-100">
      <Col md={3}>
        <Card className="p-2">
          Dash board
        </Card>
      </Col>
    </Row>
  );
};

export default DashBoard;
